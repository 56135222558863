import { Controller } from "@hotwired/stimulus";
import intlTelInput from "intl-tel-input";

export default class extends Controller {

  static get targets() {
    return [
      "firstNameInput",
      "lastNameInput",
      "emailInput",
      "phoneInput",
      "privacyAcceptanceCheckBox",
      "backwardFormButton",
      "submitFormButton",
      "loadingFormButton"
    ];
  }

  static values = {
    validFirstName:                 { type: Boolean, default: false },
    validLastName:                  { type: Boolean, default: false },
    validEmail:                     { type: Boolean, default: false },
    validPhone:                     { type: Boolean, default: false },
    validPrivacyAcceptanceCheckBox: { type: Boolean, default: false }
  }

  connect() {

    const phoneInputSelector = document.querySelector("#user_metadata_phone");

    if (phoneInputSelector !== null) {

      this.phoneInputObject = intlTelInput(phoneInputSelector, {
        initialCountry: I18n.phoneCountry,
        hiddenInput: "phone_full",
        formatOnInit: true,
        separateDialCode: true,
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.js"
      });
    }
  }

  typingFirstName(evt) {
    const firstNameValue = this.firstNameInputTarget.value;
    const firstNameMinLength = 2;

    if (firstNameValue.length <= firstNameMinLength) {

      this.validFirstNameValue = false;
      this.firstNameInputTarget.classList.remove("is-valid");

    } else {

      this.validFirstNameValue = true;
      this.firstNameInputTarget.classList.remove("is-invalid");
      this.firstNameInputTarget.classList.add("is-valid");
    }

  }

  typingLastName(evt) {
    const lastNameValue = this.lastNameInputTarget.value;
    const lastNameMinLength = 2;

    if (lastNameValue.length <= lastNameMinLength) {

      this.validLastNameValue = false;
      this.lastNameInputTarget.classList.remove("is-valid");

    } else {

      this.validLastNameValue = true;
      this.lastNameInputTarget.classList.remove("is-invalid");
      this.lastNameInputTarget.classList.add("is-valid");
    }
  }

  typingEmail(evt) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailValue = this.emailInputTarget.value;

    if (regex.test(String(emailValue).toLowerCase())) {

      this.validEmailValue = true;
      this.emailInputTarget.classList.remove("is-invalid");
      this.emailInputTarget.classList.add("is-valid");

    } else {

      this.validEmailValue = false;
      this.emailInputTarget.classList.remove("is-valid");
    }
  }

  typingPhone(evt) {
    const phoneValueSelector  = this.phoneInputTarget;
    const phonePrefixSelector = document.querySelector("input[name='user_metadata[phone_prefix]']");
    const phoneFullSelector   = document.querySelector("input[name='user_metadata[phone_full]']");
    const phoneLabelSelector  = document.querySelector("div#user_metadata_phone");

    if (this.phoneInputObject.isValidNumber()) {

      this.phoneInputObject.setNumber(evt.target.value);
      this.validPhoneValue = true;
      this.phoneInputTarget.classList.remove("is-invalid");
      this.phoneInputTarget.classList.add("is-valid");
      phoneLabelSelector.classList.remove("d-block");
      phoneValueSelector.value  = this.phoneInputObject.getNumber(2);
      phoneFullSelector.value   = this.phoneInputObject.getNumber();
      phonePrefixSelector.value = `+${this.phoneInputObject.getSelectedCountryData()["dialCode"]}`;

    } else {

      this.validPhoneValue = false;
      this.phoneInputTarget.classList.remove("is-valid");
      phoneFullSelector.value   = "";
      phonePrefixSelector.value = "";
    }
  }

  clickPrivacyAcceptanceCheckBox(evt) {
    const privacyAcceptanceLabelSelector = document.querySelector("div#user_metadata_privacy_acceptance");

    if (this.privacyAcceptanceCheckBoxTarget.checked) {

      this.validPrivacyAcceptanceCheckBoxValue = true;
      privacyAcceptanceLabelSelector.classList.remove("d-block");
    } else {

      this.validPrivacyAcceptanceCheckBoxValue = false;
    }
  }

  clickSubmitForm(evt) {
    evt.preventDefault();

    if (this.validateSubmitForm()) {
      this.backwardFormButtonTarget.disabled = true;
      this.submitFormButtonTarget.disabled = true;
      this.loadingFormButtonTarget.classList.remove("d-none");
      this.loadingFormButtonTarget.classList.add("d-block");
      document.querySelector("#quiz_form").submit();
    }
  }

  clickSubmitFormWithoutValidation(evt) {
    evt.preventDefault();

    this.backwardFormButtonTarget.disabled = true;
    this.submitFormButtonTarget.disabled = true;
    this.loadingFormButtonTarget.classList.remove("d-none");
    this.loadingFormButtonTarget.classList.add("d-block");
    document.querySelector("#quiz_form").submit();
  }

  validateSubmitForm(evt) {
    const phoneLabelSelector             = document.querySelector("div#user_metadata_phone");
    const privacyAcceptanceLabelSelector = document.querySelector("div#user_metadata_privacy_acceptance");

    this.formElementValues = [
      this.validFirstNameValue,
      this.validLastNameValue,
      this.validEmailValue,
      this.validPhoneValue,
    ]

    if (privacyAcceptanceLabelSelector !== null) {
      this.formElementValues.push(this.validPrivacyAcceptanceCheckBoxValue)
    }

    this.formElementValuesValidation = this.formElementValues.every(Boolean);

    !this.validFirstNameValue                 && this.firstNameInputTarget.classList.add("is-invalid");
    !this.validLastNameValue                  && this.lastNameInputTarget.classList.add("is-invalid");
    !this.validEmailValue                     && this.emailInputTarget.classList.add("is-invalid");
    !this.validPhoneValue                     && this.phoneInputTarget.classList.add("is-invalid");
    !this.validPhoneValue                     && phoneLabelSelector.classList.add("d-block");

    if (privacyAcceptanceLabelSelector !== null) {
      !this.validPrivacyAcceptanceCheckBoxValue && privacyAcceptanceLabelSelector.classList.add("d-block");
    }

    // Allow Submit Button
    if (this.formElementValuesValidation === false) {
      this.loadingFormButtonTarget.classList.remove("d-block");
      this.loadingFormButtonTarget.classList.add("d-none");
      this.backwardFormButtonTarget.disabled = false;
      this.submitFormButtonTarget.disabled = false;
    }

    // Debug Values
    // this.debugFormValues()

    return this.formElementValuesValidation;
  }

  debugFormValues(evt) {
    console.log(`Firstname: ${this.validFirstNameValue}`);
    console.log(`Lastname: ${this.validLastNameValue}`);
    console.log(`Email: ${this.validEmailValue}`);
    console.log(`Phone Number: ${this.validPhoneValue}`);
    console.log(`Compliance Policy: ${this.validPrivacyAcceptanceCheckBoxValue}`);
    console.log(`Form Element Values: ${this.formElementValues}`);
    console.log(`Form Element Values Validation: ${this.formElementValuesValidation}`);
  }
}