import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    jQuery(".containerWizard").wizard({
      stepsWrapper: "#quiz_form",
      submit: ".submit",
      beforeSelect: function(event, state) {
        if (!state.isMovingForward) return true;
      },

      afterBackward: function(event, state) {
        var inputs = jQuery(this).wizard("state").step.find(":input");
        jQuery(inputs).prop("checked", false);

        jQuery("button.submit").addClass("d-none");
        jQuery("button.forward").addClass("d-none");
      },

      afterForward: function(event, state) {
        var inputs = jQuery(this).wizard("state").step.find(":input");
        jQuery(inputs).prop("checked", false);
      }
    });

    // Progress Bar
    jQuery("#progressbar").progressbar();
    jQuery(".containerWizard").wizard({
      afterSelect: function(event, state) {
        jQuery("#progressbar").progressbar("value", state.percentComplete);
      }
    });
  }

  clickButton() {
    setTimeout(() => {
      jQuery("button.forward").click();
    }, 100)
  }

  showSubmitButton() {
    jQuery("button.submit").removeClass("d-none");
    jQuery("button.forward").removeClass("d-none");
  }
}