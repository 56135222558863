import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static get targets() {
    return [
      "passwordInput",
      "backwardFormButton",
      "submitFormButton",
      "loadingFormButton"
    ];
  }

  static values = {
    validPassword: { type: Boolean, default: false },
  }

  connect() {}

  typingPassword(evt) {
    if (this.passwordInputTarget.value.toLowerCase() != this.data.get("protectedPass")) {
      this.validPasswordValue = false;
      this.passwordInputTarget.classList.remove("is-valid");
    } else {
      this.validPasswordValue = true;
      this.passwordInputTarget.classList.remove("is-invalid");
      this.passwordInputTarget.classList.add("is-valid");
    }
  }

  clickSubmitForm(evt) {
    evt.preventDefault();

    if (this.validateSubmitForm()) {
      this.submitFormButtonTarget.disabled = true;
      this.loadingFormButtonTarget.classList.remove("d-none");
      this.loadingFormButtonTarget.classList.add("d-block");
      document.querySelector("#quiz_form").submit();
    }
  }

  validateSubmitForm(evt) {
    this.formElementValues = [
      this.validPasswordValue,
    ]

    this.formElementValuesValidation = this.formElementValues.every(Boolean);

    !this.validPasswordValue && this.passwordInputTarget.classList.add("is-invalid");

    // Allow Submit Button
    if (this.formElementValuesValidation === false) {
      this.loadingFormButtonTarget.classList.remove("d-block");
      this.loadingFormButtonTarget.classList.add("d-none");
      this.submitFormButtonTarget.disabled = false;
    }

    // Debug Values
    this.debugFormValues()

    return this.formElementValuesValidation;
  }

  debugFormValues(evt) {
    console.log(`Password: ${this.validPasswordValue}`);
  }
}