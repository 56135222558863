// Imports
import { Controller } from "@hotwired/stimulus"

// Controller
export default class extends Controller {
  connect() {

    jQuery("#pre-loader > [data-loader='protagonistas-symbol']").fadeOut();
    jQuery("#pre-loader").delay(500).fadeOut("slow");
    jQuery("body").delay(500).css({
      "overflow": "visible"
    });
  }
}